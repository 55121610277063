<template>
  <section id="main-slider-1" class="main-swiper-container">
    <swiper class="swiper-main" :modules="modules" :slides-per-view="1" :pagination="{ clickable: true }" :autoplay="{
      delay: 50000,
      disableOnInteraction: false,
    }">
      <swiper-slide class="swiper-main-slide">
        <div class="swiper-main-slide-1">
          <div class="first-swiper__image">
            <div class="first-swiper__image-mobile first-swiper__image-mobile-1"></div>
            <div class="first-swiper__image-desktop first-swiper__image-desktop-1"></div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>
<!--  -->
<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import TimeCounter from "./TimeCounter.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    TimeCounter,
  },
  data() {
    return {
      modules: [Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
        form_position: "форма первого баннера",
      });
    },
  },
};
</script>
