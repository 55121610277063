<template>
  <swiper
    v-if="width < 1024"
    class="model-swiper"
    :modules="modules"
    :spaceBetween="10"
    :loop="true"
    :freeMode="true"
    :slidesPerView="2"
    :breakpoints="swiperOptions.breakpoints"
    :autoplay="{
      delay: 4000,
      disableOnInteraction: true,
    }"
  >
    <swiper-slide
      v-for="(img, index) in images"
      :key="index"
      class="model-swiper__slide"
    >
      <img
        :src="require(`../../img/pics/cars/${id}/slider/${img}.jpg`)"
        alt=""
      />
    </swiper-slide>
  </swiper>

  <div v-else class="modelSwiperContainer">
    <Swiper
      class="mySwiper"
      :modules="modules"
      :spaceBetween="5"
      :slides-offset-before="25"
      :direction="'vertical'"
      :autoHeight="true"
      :loop="true"
      :navigation="swiperOptions.navigation"
      :breakpoints="swiperOptions.breakpointsDesktop"
    >
      <SwiperSlide
        v-for="(img, index) in images"
        :key="index"
        class="model-swiper__slide"
      >
        <img
          :src="require(`../../img/pics/cars/${id}/slider/${img}.jpg`)"
          alt=""
        />
      </SwiperSlide>
      <div class="custom-nav-prev"></div>
      <div class="custom-nav-next"></div>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Scrollbar,
  A11y,
  Navigation,
  Pagination,
  Autoplay,
  FreeMode,
} from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import "swiper/css/grid";
import "swiper/css/free-mode";
export default {
  props: ["model"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      id: this.model.id,
      images: this.model.slider_img,
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay, FreeMode],
      width: document.documentElement.clientWidth,
      swiperOptions: {
        breakpoints: {
          768: {
            slidesPerView: 4,
          },
        },
        breakpointsDesktop: {
          800: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
        navigation: {
          nextEl: ".custom-nav-next",
          prevEl: ".custom-nav-prev",
        },
      },
    };
  },
  mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.mySwiper {
  height: 100%;
  overflow: hidden;
}
.modelSlideImage {
  padding-top: 10rem;
}
</style>
