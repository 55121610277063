<template>
  <section id="promo" class="know-more">
    <div class="know-more__content">
      <h2 class="know-more__question section-title">{{ question }}</h2>
      <h3 class="know-more__answer section-title">{{ answer }}</h3>
      <button
        @click="toggleRequestForm($event)"
        class="know-more__btn btn-primary"
      >
        {{ button_text }}
      </button>
    </div>
  </section>
</template>

<script>
import siteData from "@/config.json";

export default {
  name: "KnowMoreBlock",
  data() {
    return {
      question: siteData.know_more_block.question,
      answer: siteData.know_more_block.answer,
      button_text: siteData.know_more,
    };
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
        form_position: "форма Узнать больше",
      });
    },
  },
};
</script>
