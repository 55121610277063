<template>
  <section id="main-slider-2" class="main-swiper-container">
    <swiper class="swiper-main" :modules="modules" :slides-per-view="1" :pagination="{ clickable: true }" :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }">
      <swiper-slide class="swiper-main-slide">
        <SliderComponent>
          <template #image>
            <div class="second-swiper__image">
              <div class="second-swiper__image-mobile second-swiper__image-mobile-1"></div>
              <div class="second-swiper__image-desktop second-swiper__image-desktop-1"></div>
            </div>
          </template>

          <template #title>
            Рассрочка 0% на кроссоверы KAIYI
          </template>
          <template #sliderButton>Узнать больше</template>
        </SliderComponent>
      </swiper-slide>
    </swiper>
  </section>
</template>
<!--  -->
<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, A11y, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import SliderComponent from "./SliderComponent.vue";
export default {
  name: "MainSlider",
  components: {
    Swiper,
    SwiperSlide,
    SliderComponent,
  },
  data() {
    return {
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay],
      know_more: siteData.know_more,
      sliders: siteData.main_slider,
    };
  },
};
</script>
