<template>
  <div class="tabs-swiper__container">
    <!-- <div class="tabs-swiper__menu">
      <button
        class="models__menu__item tabs-swiper__menu-item"
        v-for="model in models"
        href="#"
        :key="model"
        @click="handleSelectedModel(model)"
      >
        {{ model }}
      </button>
    </div> -->
    <swiper
      :modules="modules"
      :spaceBetween="25"
      :freeMode="true"
      :slidesPerView="4"
      :navigation="swiperOptions.navigation"
    >
      <swiper-slide
        v-for="(tab, index) in selectedModel"
        :key="index"
        class="tabs-swiper-slide"
      >
        <img :src="tab.image" :alt="tab.model" class="tab__image" />
        <h3 class="tab__title">{{ tab.brand }} {{ tab.model }}</h3>
        <p class="tab__vin">VIN: {{ tab.vin }}</p>
        <p class="tab__details">
          {{ tab.gearboxType }} &middot; {{ tab.driveType }} &middot;
          {{ tab.engine.type }} &middot; {{ tab.engine.volume }} &middot;
          {{ tab.engine.power }}
        </p>
        <p class="tab__color">Цвет: {{ tab.bodyType.color }}</p>
        <!-- <p class="tab__city">г. {{ tab.location }}</p> -->
        <p class="tab__city">г. Екатеринбург, Ул Щербакова 144</p>
        <hr />
        <p class="tab__old-price">{{ priceDecoration(tab.price.regular) }} ₽</p>
        <!-- <p v-if="tab.price.withDiscount" class="tab__new-price">
          {{ priceDecoration(tab.price.withDiscount) }} ₽
        </p>
        <p v-else class="tab__new-price">Цена по запросу</p> -->
        <p class="tab__credit">{{ getCredit(tab.model) }}</p>
        <button
          @click="toggleRequestForm($event, tab.model)"
          class="tab__btn btn-secondary"
        >
          Узнать цену по акции
        </button>
      </swiper-slide>
    </swiper>
    <button class="prev-slide"></button>
    <button class="next-slide"></button>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Scrollbar,
  A11y,
  Navigation,
  Pagination,
  Autoplay,
  FreeMode,
} from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
export default {
  props: ["tabs", "selectedModel"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      //swiper options
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay, FreeMode],
      width: document.documentElement.clientWidth,
      swiperOptions: {
        navigation: {
          nextEl: ".next-slide",
          prevEl: ".prev-slide",
        },
      },
    };
  },
  async mounted() {
    window.addEventListener("resize", this.getDimensions);
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
    },
    toggleRequestForm(event, model) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
        car: model,
        form_position: "форма Автомобили в наличии",
      });
    },
    priceDecoration(price) {
      return new Intl.NumberFormat("ru", {
        maximumSignificantDigits: 3,
      }).format(price);
    },
    getCredit(model) {
      switch (model) {
        case "Tiggo 4 Pro":
          return "от 8 500 ₽ в месяц";
        case "Tiggo 7 Pro":
          return "от 7 500 ₽ в месяц";
        case "Tiggo 8":
          return "от 12 000 ₽ в месяц";
        case "Tiggo 8 Pro":
          return "от 16 000 ₽ в месяц";
        case "Tiggo 8 Pro Max New":
          return "от 16 900 ₽ в месяц";
        default:
          return "Кредит по запросу";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.next-slide::after,
.prev-slide::after {
  content: "";
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 20rem;
  z-index: 5;
  border-radius: 50%;
}
.next-slide::after {
  background: url(../../img/icons/arrow-right-g.svg) center no-repeat;
  background-color: #f5f5f5;
  right: 3rem;
}
.prev-slide::after {
  background: url(../../img/icons/arrow-left-g.svg) center no-repeat;
  background-color: #f5f5f5;
  left: 3rem;
}
.next-slide:disabled::after,
.next-slide[disabled]::after,
.prev-slide:disabled::after,
.prev-slide[disabled]::after {
  opacity: 0.5;
  filter: grayscale(1);
}
</style>
