<template>
  <section id="footer" class="footer">
    <div class="footer__info">
      <p class="footer__info-text">
        ООО «Автобан Березовский Плюс» | ОГРН 1227700915716 | ИНН 9709089291 | КПП 770901001
      </p>
      <a @click="togglePrivacy" class="footer__policy"
        >©2023 | Политика конфиденциальности</a
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    togglePrivacy() {
      this.emitter.emit("open-privacy", {
        open: false,
      });
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;
      getComputedStyle(element).height;
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
  mounted() {
    this.emitter.on("open-privacy", (data) => {
      this.privacyIsHidden = data.open;
    });
  },
};
</script>

<style lang="scss" scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.expand-enter-active,
.expand-leave-active {
  transition: height 0.5s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
</style>
