<template>
  <section id="team" class="team">
    <h2 class="team-title">Наша команда</h2>
    <swiper :modules="modules" :slides-per-view="1" :space-between="10" :navigation="swiperOptions.navigation" :autoplay="{
      delay: 50000,
      disableOnInteraction: false,
    }" :pagination="{ clickable: true }">
      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/8.jpg" />
            <div class="team__employee__content-text">
              <p>
                Сотрудники компании «Автобан» — настоящие профессионалы своего
                дела и главное достояние нашей компании. Клиентоориентированные,
                целеустремленные - сотрудники высокого класса. Секрет компании -
                мы настоящая команда, не только работающая на общий результат,
                но и скрепленная атмосферой уважения и поддержки со стороны
                руководителей и своих коллег. Компания Автобан делает все для
                привлечения лучших специалистов и содействует их
                профессиональному развитию, а это залог предоставления
                качественных услуг, надежного и долгосрочного партнерства.
              </p>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/98.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Ткачев Константин</h3>
              <h3 class="team__employee-job">директор</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/22.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Липенских Максим</h3>
              <h3 class="team__employee-job">Специалист отдела трейд ин</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/83.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Бабушкин Александр</h3>
              <h3 class="team__employee-job">Специалист отдела трейд ин</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/107.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Филимонова Олеся</h3>
              <h3 class="team__employee-job">логист</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/50.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Наумова Мария</h3>
              <h3 class="team__employee-job">администратор</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/70.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name"> Зенченко Евгения</h3>
              <h3 class="team__employee-job"> руководитель клиентской службы</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/76.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Минина Юлия</h3>
              <h3 class="team__employee-job">руководитель отдела продаж</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/88.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Удилин Андрей</h3>
              <h3 class="team__employee-job">мастер-приемщик</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/92.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Зорина Мария</h3>
              <h3 class="team__employee-job">оператор сервиса</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/101.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Мышкин Павел</h3>
              <h3 class="team__employee-job">Специалист отдела трейд ин</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/111.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Мингалева Ольга</h3>
              <h3 class="team__employee-job">менеджер кредитно-страхового отдела</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/13.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Бондаренко Даниил</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/16.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Стенников Иван</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/03.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Савиных Ксения</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/30.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Чибук Владислав</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/60.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Лукьяненко Артур</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/62.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Герасимов Антон</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>


      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/45.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Трофимова Марина</h3>
              <h3 class="team__employee-job">менеджер кредитно-страхового отдела</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/103.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Тихонов Александр</h3>
              <h3 class="team__employee-job">руководитель отдела з/ч</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/31.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Викторова Ольга</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/05.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Пращерук Евгений</h3>
              <h3 class="team__employee-job">менеджер отдела продаж</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/78.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Куров Виталий</h3>
              <h3 class="team__employee-job">специалист отдела трейд ин</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/39.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Ужегов Евгений</h3>
              <h3 class="team__employee-job">специалист отдела трейд ин</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/84.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Новоселов Владимир</h3>
              <h3 class="team__employee-job">специалист отдела трейд ин</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/52.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Городилова Екатерина</h3>
              <h3 class="team__employee-job">менеджер кредитно-страхового отдела</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/71.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Ошкукова Наталья</h3>
              <h3 class="team__employee-job">менеджер кредитно-страхового отдела</h3>
            </div>
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/97.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Красноперова Ольга</h3>
              <h3 class="team__employee-job">администратор сервиса</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div class="team__content">
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/8.jpg" />
          </div>
          <div class="team__employee team__employee">
            <img src="../../img/pics/team/98-b.jpg" />
            <div class="team__employee__content">
              <h3 class="team__employee-name">Семенов Алексей</h3>
              <h3 class="team__employee-job">мастер-приемщик отдела сервиса</h3>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <button class="prev-slide-team"></button>
      <button class="next-slide-team"></button>
    </swiper>
  </section>
</template>

<script>
import siteData from "@/config.json";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar, A11y, Navigation, Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
export default {
  name: "TeamComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Scrollbar, A11y, Navigation, Pagination, Autoplay],
      swiperOptions: {
        navigation: {
          nextEl: ".next-slide-team",
          prevEl: ".prev-slide-team",
        },
      },
    };
  },
};
</script>
