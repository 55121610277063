<template>
  <section id="header">
    <nav id="navMob" class="navbar__mobile" :class="{ sticky: offset }">
      <Transition name="fade">
        <span v-show="!isHidden" class="navbar__mobile-bg"></span>
      </Transition>
      <div class="navbar__mobile__logo">
        <img
          src="@/img/common/logo.png"
          alt="Kaiyi logo"
          class="navbar__mobile__logo-chery"
        />
        <svg
          class="navbar__mobile__logo-company"
          width="118"
          height="21"
          viewBox="0 0 118 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.37196 12.4907H11.2938L8.78444 6.21624C8.69589 5.99574 8.54192 5.57773 8.34391 5.57773C8.14593 5.57773 7.99133 5.99574 7.90335 6.21624L5.37192 12.4907H5.37196ZM5.81195 4.60881C6.36242 3.31021 6.95702 2.86987 8.34394 2.86987C9.6649 2.86987 10.3255 3.31025 10.8759 4.60881L16.6878 18.4566H13.6273L12.0424 14.5596H4.62345L3.0599 18.4566H0L5.81192 4.60881H5.81195Z"
            fill="#1A2C38"
          />
          <path
            d="M21.2541 15.0002C21.2541 15.5068 21.3202 15.7707 21.4301 15.9027C21.6727 16.1673 22.1567 16.1452 22.3772 16.1452H26.9561C27.4186 16.1452 28.211 16.1232 28.6516 15.5947C28.9816 15.1982 29.0475 14.5157 29.0475 14.0317C29.0475 13.5913 29.0035 12.8868 28.7175 12.4682C28.541 12.2263 28.101 11.8302 27.1541 11.8302H21.2541V15.0002H21.2541ZM26.8901 9.51828C27.4627 9.51828 28.0791 9.51828 28.4751 8.96832C28.5851 8.83574 28.8056 8.46173 28.8056 7.40481C28.8056 6.9868 28.7836 6.21624 28.299 5.75375C27.8805 5.35739 27.3527 5.35739 26.8681 5.35739H22.1127C21.8707 5.35739 21.6286 5.3354 21.4522 5.53391C21.2761 5.73177 21.2541 6.03977 21.2541 6.21627V9.51831H26.8901V9.51828ZM28.035 3.0459C28.8715 3.0459 30.016 3.13378 30.831 3.9703C31.6896 4.85137 31.7996 6.26031 31.7996 7.27285C31.7996 8.10921 31.7556 8.96832 31.073 9.78279C30.6325 10.3107 30.1924 10.5313 29.6201 10.5752V10.6632C30.3465 10.7293 30.941 11.0592 31.315 11.4777C32.0415 12.2701 32.0415 13.3712 32.0415 14.2297C32.0415 15.2422 32.0415 16.4751 31.161 17.4662C30.2804 18.4566 29.2893 18.4566 28.343 18.4566H20.9682C20.1977 18.4566 19.4924 18.5446 18.8766 17.84C18.7221 17.6641 18.3483 17.1577 18.3483 16.2772V5.26918C18.3483 4.69686 18.5023 4.08081 18.8326 3.68444C19.2946 3.13375 19.9772 3.0459 20.4616 3.0459H28.0349H28.035Z"
            fill="#1A2C38"
          />
          <path
            d="M40.0829 18.4566V5.35739H34.0729V3.0459H49.0212V5.35739H42.9887V18.4566H40.0829Z"
            fill="#1A2C38"
          />
          <path
            d="M73.8136 14.9473C73.8136 15.6078 73.8794 15.7397 73.9236 15.8057C74.1436 16.1142 74.694 16.0923 75.0247 16.0923H79.6915C80.22 16.0923 80.7705 16.0923 81.2105 15.6517C81.695 15.1672 81.673 14.3088 81.673 13.8469C81.673 13.2743 81.6511 12.6138 81.1885 12.1733C80.7705 11.7774 80.1762 11.7554 79.6915 11.7554H73.8136V14.9473ZM83.8743 5.30438H74.848C74.5622 5.30438 74.2322 5.32652 74.0335 5.48087C73.8136 5.67885 73.8136 6.05292 73.8136 6.25143V9.44332H81.1444C81.9156 9.44332 83.0818 9.57534 83.8743 10.5884C84.4469 11.3369 84.6669 12.5039 84.6669 13.9349C84.6669 15.9157 84.2711 16.8848 83.8303 17.3914C83.5005 17.7652 82.708 18.4038 81.1885 18.4038H72.8671C72.0745 18.4038 71.6122 18.1178 71.3254 17.7872C70.9516 17.3474 70.9076 16.7968 70.9076 16.3563V5.23835C70.9076 4.71047 70.9736 4.04997 71.4361 3.56544C71.7001 3.30143 72.1847 2.99292 72.9991 2.99292H83.8743V5.30438H83.8743Z"
            fill="#1A2C38"
          />
          <path
            d="M91.3647 12.3318H97.2865L94.777 6.05746C94.6885 5.83696 94.5345 5.41892 94.3365 5.41892C94.1385 5.41892 93.9842 5.83696 93.896 6.05746L91.3647 12.3318ZM91.8045 4.44988C92.355 3.15144 92.9496 2.71094 94.3365 2.71094C95.6575 2.71094 96.3181 3.15147 96.8679 4.44988L102.68 18.2978H99.6199L98.035 14.4008H90.6161L89.0525 18.2978H85.9927L91.8045 4.44988Z"
            fill="#1A2C38"
          />
          <path
            d="M118 18.4566H115.102V11.6756H107.089V18.4566H104.183V3.0459H107.089V9.36422H115.102V3.0459H118V18.4566Z"
            fill="#1A2C38"
          />
          <path
            d="M69.6907 10.1043C69.6907 15.6848 65.167 20.2086 59.5864 20.2086C54.0059 20.2086 49.4821 15.6848 49.4821 10.1043C49.4821 4.52383 54.0059 0 59.5864 0C65.167 0 69.6907 4.52383 69.6907 10.1043Z"
            fill="#1A2C38"
          />
          <path
            d="M55.5392 13.1406H56.1952V7.0498H55.5392V13.1406Z"
            fill="#FEFEFE"
          />
          <path
            d="M59.2081 13.1406H59.8641V7.0498H59.2081V13.1406Z"
            fill="#FEFEFE"
          />
          <path
            d="M62.8773 13.1406H63.5331V7.0498H62.8773V13.1406Z"
            fill="#FEFEFE"
          />
          <path
            d="M55.5311 10.4233H63.5255V9.76733H55.5311V10.4233Z"
            fill="#FD0000"
          />
          <path
            d="M55.8423 4.67875C55.8423 4.65222 55.8404 4.61676 55.8268 4.59629C55.8112 4.57209 55.7914 4.57209 55.7691 4.57209H55.5973V4.33765H55.934C55.9961 4.33765 56.0452 4.35377 56.0786 4.38049C56.1327 4.42515 56.1369 4.48711 56.1369 4.5343V5.8982H55.8423V4.67875H55.8423Z"
            fill="#FEFEFE"
          />
          <path
            d="M58.9621 4.34137H59.7442C59.8293 4.34137 59.9539 4.34642 60.0409 4.4574C60.1102 4.54673 60.1146 4.66954 60.1146 4.75197C60.1146 4.8432 60.1077 4.94998 60.0409 5.02622C60.0123 5.05963 59.9582 5.09944 59.8801 5.10616V5.11307C59.952 5.11493 60.0123 5.14647 60.0501 5.1819C60.1344 5.26436 60.1369 5.38713 60.1369 5.47164C60.1369 5.60129 60.1257 5.71902 60.034 5.81088C59.9538 5.89082 59.8603 5.90205 59.7691 5.90205H58.9354V5.66811H59.5618C59.6597 5.66811 59.7219 5.66811 59.7647 5.64139C59.8292 5.59858 59.8336 5.523 59.8336 5.456C59.8336 5.38477 59.831 5.31575 59.7691 5.26871C59.7218 5.23329 59.6505 5.23092 59.6219 5.23092H58.975V4.99698H59.604C59.6505 4.99698 59.7244 4.99446 59.7691 4.94562C59.8068 4.90329 59.8112 4.83629 59.8112 4.78304C59.8112 4.72711 59.8068 4.66443 59.7691 4.62244C59.7288 4.58011 59.6728 4.57591 59.6283 4.57591H58.9621V4.34131L58.9621 4.34137Z"
            fill="#FEFEFE"
          />
          <path
            d="M62.6143 5.66829H63.3096C63.3545 5.66829 63.4282 5.66343 63.4685 5.60144C63.4901 5.56536 63.5014 5.48788 63.5014 5.42962C63.5014 5.38731 63.4971 5.31359 63.4685 5.27375C63.4524 5.25145 63.4146 5.21549 63.3321 5.21549H62.9176C62.8575 5.21549 62.757 5.21549 62.6944 5.17082C62.6074 5.10883 62.6057 4.99028 62.6057 4.93703V4.54256C62.6057 4.46679 62.6324 4.42231 62.6548 4.39748C62.7055 4.34404 62.7817 4.34155 62.8128 4.34155H63.7536V4.57616H62.9735C62.9512 4.57616 62.9245 4.58035 62.9134 4.59597C62.8998 4.61146 62.8998 4.63814 62.8998 4.65609V4.85025C62.8998 4.88117 62.8998 4.92599 62.9245 4.95255C62.9486 4.97741 62.9977 4.98158 63.0293 4.98158H63.4592C63.5528 4.98158 63.6465 5.00158 63.7248 5.09965C63.8027 5.19757 63.8045 5.31179 63.8045 5.41842C63.8045 5.55917 63.7958 5.71734 63.6979 5.81296C63.6062 5.90226 63.4839 5.90226 63.4014 5.90226H62.6143V5.66832V5.66829Z"
            fill="#FEFEFE"
          />
          <path
            d="M59.7597 14.6254C59.7597 14.6192 59.7553 14.6168 59.751 14.6168C59.7468 14.6168 59.7424 14.6192 59.7244 14.6391L59.1271 15.301C59.116 15.3121 59.1003 15.3283 59.1003 15.3388C59.1003 15.3481 59.1047 15.366 59.1401 15.366H59.7597V14.6254L59.7597 14.6254ZM59.7597 15.5999H59.0112C58.9707 15.5999 58.92 15.5999 58.8728 15.5527C58.8461 15.5242 58.8193 15.4727 58.8193 15.3834C58.8193 15.3233 58.8306 15.2631 58.9062 15.1806L59.6598 14.3736C59.7332 14.2935 59.8249 14.2849 59.8758 14.2849C59.9204 14.2849 59.9762 14.2911 60.0167 14.3339C60.0563 14.376 60.0544 14.4164 60.0544 14.4542V15.366H60.2529V15.5999H60.0544V15.8629H59.7597V15.5999V15.5999Z"
            fill="#FEFEFE"
          />
          <path
            d="M55.4149 15.8707C55.3859 15.8707 55.25 15.8664 55.25 15.6834C55.25 15.5606 55.2991 15.5098 55.3455 15.4787L56.0681 14.9837C56.1593 14.9211 56.1662 14.8473 56.1662 14.7586C56.1662 14.6804 56.1593 14.629 56.1344 14.5979C56.1079 14.5644 56.0519 14.5445 55.9762 14.5445H55.3548V14.3101H56.1127C56.1842 14.3101 56.2951 14.3194 56.3758 14.4019C56.4601 14.4863 56.4694 14.6091 56.4694 14.7251C56.4694 14.8094 56.4669 14.9348 56.3981 15.0346C56.3578 15.0929 56.3088 15.1264 56.2617 15.1599L55.6333 15.5965C55.6264 15.601 55.6084 15.6121 55.6084 15.6233C55.6084 15.6276 55.6109 15.6368 55.6264 15.6368H56.4024V15.8707H55.4149L55.4149 15.8707Z"
            fill="#FEFEFE"
          />
          <path
            d="M62.4875 14.5683C62.4875 14.5037 62.4893 14.4256 62.552 14.3655C62.6077 14.3121 62.6809 14.3027 62.7392 14.3027H63.4793C63.5883 14.3027 63.7224 14.3096 63.8136 14.4454C63.8873 14.5528 63.8918 14.6756 63.8918 14.7891C63.8918 14.9026 63.8873 15.0255 63.8246 15.1414C63.7845 15.2172 63.7088 15.3015 63.5301 15.3015H63.3297V15.3126L63.9228 15.8634H63.5524L63.04 15.3573C63.0091 15.3264 62.9663 15.2704 62.9663 15.2258V15.1905C62.9663 15.1277 63.0135 15.0831 63.0978 15.0831H63.3924C63.4433 15.0831 63.5128 15.0764 63.5524 15.0142C63.5842 14.9653 63.5884 14.8536 63.5884 14.8003C63.5884 14.6775 63.5637 14.6155 63.5326 14.5838C63.4861 14.5373 63.4147 14.5373 63.3677 14.5373H62.8907C62.8596 14.5373 62.8373 14.5391 62.8124 14.5615C62.7814 14.5882 62.7814 14.6173 62.7814 14.6484V15.8634H62.4874V14.5683L62.4875 14.5683Z"
            fill="#FD0000"
          />
        </svg>
      </div>
      <button @click="toggleRequestForm" class="navbar__mobile__form">
        <img src="@/img/icons/call-answer-g.svg" alt="phone icon" />
      </button>
      <button
        :class="{ hide: !isHidden }"
        class="navbar__mobile__burger-btn"
        @click="toggleNavlinks"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      <Transition name="slideMenu">
        <div class="navbar__mobile__links" v-show="!isHidden">
          <a
            v-for="(link, index) in navbar"
            :key="{ index }"
            :href="`#${link.id}`"
            class="navbar__mobile__links-item"
            @click="toggleNavlinks()"
            >{{ link.name }}</a
          >
          <div class="navbar__mobile__links-contacts">
            <div
              v-for="contact in contacts"
              :key="contact.phone"
              class="navbar__mobile__links-contact"
            >
              <p>
                <span><img src="@/img/icons/map_bullit-g.svg" alt="" /></span>
                {{ contact.street }}
              </p>
              <a :href="`tel:${contact.phone_number}`">{{
                contact.phone_text
              }}</a>
            </div>
          </div>
          <div class="navbar__mobile__links-socials">
            <a href="https://web.telegram.org/z/#-1770343263" target="_blank">
              <img src="@/img/icons/telegram-gray-g.svg" alt="telegram logo"
            /></a>
            <a href="https://vk.com/avtoban_chery" target="_blank">
              <img src="@/img/icons/vk-gray-g.svg" alt="VK logo"
            /></a>
          </div>
        </div>
      </Transition>
    </nav>

    <nav id="navDesk" class="header__desktop" :class="{ sticky: offset }">
      <div class="header__contacts">
        <div
          v-for="contact in contacts"
          :key="contact.phone"
          class="header__contact"
        >
          <p>
            <span
              ><img src="@/img/icons/map_bullit-g.svg" alt="map marker icon"
            /></span>
            {{ contact.city }} {{ contact.street }}
          </p>
          <a :href="`tel:{contact.phone_number}`">{{ contact.phone_text }}</a>
        </div>
      </div>
      <div class="header__navbar">
        <div class="header__navbar__logo">
          <img
            src="@/img/common/logo.png"
            alt="Kaiyi logo"
            class="header__navbar__logo-chery"
          />

          <svg
            class="header__navbar__logo-company"
            width="118"
            height="21"
            viewBox="0 0 118 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.37196 12.4907H11.2938L8.78444 6.21624C8.69589 5.99574 8.54192 5.57773 8.34391 5.57773C8.14593 5.57773 7.99133 5.99574 7.90335 6.21624L5.37192 12.4907H5.37196ZM5.81195 4.60881C6.36242 3.31021 6.95702 2.86987 8.34394 2.86987C9.6649 2.86987 10.3255 3.31025 10.8759 4.60881L16.6878 18.4566H13.6273L12.0424 14.5596H4.62345L3.0599 18.4566H0L5.81192 4.60881H5.81195Z"
              fill="#1A2C38"
            />
            <path
              d="M21.2541 15.0002C21.2541 15.5068 21.3202 15.7707 21.4301 15.9027C21.6727 16.1673 22.1567 16.1452 22.3772 16.1452H26.9561C27.4186 16.1452 28.211 16.1232 28.6516 15.5947C28.9816 15.1982 29.0475 14.5157 29.0475 14.0317C29.0475 13.5913 29.0035 12.8868 28.7175 12.4682C28.541 12.2263 28.101 11.8302 27.1541 11.8302H21.2541V15.0002H21.2541ZM26.8901 9.51828C27.4627 9.51828 28.0791 9.51828 28.4751 8.96832C28.5851 8.83574 28.8056 8.46173 28.8056 7.40481C28.8056 6.9868 28.7836 6.21624 28.299 5.75375C27.8805 5.35739 27.3527 5.35739 26.8681 5.35739H22.1127C21.8707 5.35739 21.6286 5.3354 21.4522 5.53391C21.2761 5.73177 21.2541 6.03977 21.2541 6.21627V9.51831H26.8901V9.51828ZM28.035 3.0459C28.8715 3.0459 30.016 3.13378 30.831 3.9703C31.6896 4.85137 31.7996 6.26031 31.7996 7.27285C31.7996 8.10921 31.7556 8.96832 31.073 9.78279C30.6325 10.3107 30.1924 10.5313 29.6201 10.5752V10.6632C30.3465 10.7293 30.941 11.0592 31.315 11.4777C32.0415 12.2701 32.0415 13.3712 32.0415 14.2297C32.0415 15.2422 32.0415 16.4751 31.161 17.4662C30.2804 18.4566 29.2893 18.4566 28.343 18.4566H20.9682C20.1977 18.4566 19.4924 18.5446 18.8766 17.84C18.7221 17.6641 18.3483 17.1577 18.3483 16.2772V5.26918C18.3483 4.69686 18.5023 4.08081 18.8326 3.68444C19.2946 3.13375 19.9772 3.0459 20.4616 3.0459H28.0349H28.035Z"
              fill="#1A2C38"
            />
            <path
              d="M40.0829 18.4566V5.35739H34.0729V3.0459H49.0212V5.35739H42.9887V18.4566H40.0829Z"
              fill="#1A2C38"
            />
            <path
              d="M73.8136 14.9473C73.8136 15.6078 73.8794 15.7397 73.9236 15.8057C74.1436 16.1142 74.694 16.0923 75.0247 16.0923H79.6915C80.22 16.0923 80.7705 16.0923 81.2105 15.6517C81.695 15.1672 81.673 14.3088 81.673 13.8469C81.673 13.2743 81.6511 12.6138 81.1885 12.1733C80.7705 11.7774 80.1762 11.7554 79.6915 11.7554H73.8136V14.9473ZM83.8743 5.30438H74.848C74.5622 5.30438 74.2322 5.32652 74.0335 5.48087C73.8136 5.67885 73.8136 6.05292 73.8136 6.25143V9.44332H81.1444C81.9156 9.44332 83.0818 9.57534 83.8743 10.5884C84.4469 11.3369 84.6669 12.5039 84.6669 13.9349C84.6669 15.9157 84.2711 16.8848 83.8303 17.3914C83.5005 17.7652 82.708 18.4038 81.1885 18.4038H72.8671C72.0745 18.4038 71.6122 18.1178 71.3254 17.7872C70.9516 17.3474 70.9076 16.7968 70.9076 16.3563V5.23835C70.9076 4.71047 70.9736 4.04997 71.4361 3.56544C71.7001 3.30143 72.1847 2.99292 72.9991 2.99292H83.8743V5.30438H83.8743Z"
              fill="#1A2C38"
            />
            <path
              d="M91.3647 12.3318H97.2865L94.777 6.05746C94.6885 5.83696 94.5345 5.41892 94.3365 5.41892C94.1385 5.41892 93.9842 5.83696 93.896 6.05746L91.3647 12.3318ZM91.8045 4.44988C92.355 3.15144 92.9496 2.71094 94.3365 2.71094C95.6575 2.71094 96.3181 3.15147 96.8679 4.44988L102.68 18.2978H99.6199L98.035 14.4008H90.6161L89.0525 18.2978H85.9927L91.8045 4.44988Z"
              fill="#1A2C38"
            />
            <path
              d="M118 18.4566H115.102V11.6756H107.089V18.4566H104.183V3.0459H107.089V9.36422H115.102V3.0459H118V18.4566Z"
              fill="#1A2C38"
            />
            <path
              d="M69.6907 10.1043C69.6907 15.6848 65.167 20.2086 59.5864 20.2086C54.0059 20.2086 49.4821 15.6848 49.4821 10.1043C49.4821 4.52383 54.0059 0 59.5864 0C65.167 0 69.6907 4.52383 69.6907 10.1043Z"
              fill="#1A2C38"
            />
            <path
              d="M55.5392 13.1406H56.1952V7.0498H55.5392V13.1406Z"
              fill="#FEFEFE"
            />
            <path
              d="M59.2081 13.1406H59.8641V7.0498H59.2081V13.1406Z"
              fill="#FEFEFE"
            />
            <path
              d="M62.8773 13.1406H63.5331V7.0498H62.8773V13.1406Z"
              fill="#FEFEFE"
            />
            <path
              d="M55.5311 10.4233H63.5255V9.76733H55.5311V10.4233Z"
              fill="#FD0000"
            />
            <path
              d="M55.8423 4.67875C55.8423 4.65222 55.8404 4.61676 55.8268 4.59629C55.8112 4.57209 55.7914 4.57209 55.7691 4.57209H55.5973V4.33765H55.934C55.9961 4.33765 56.0452 4.35377 56.0786 4.38049C56.1327 4.42515 56.1369 4.48711 56.1369 4.5343V5.8982H55.8423V4.67875H55.8423Z"
              fill="#FEFEFE"
            />
            <path
              d="M58.9621 4.34137H59.7442C59.8293 4.34137 59.9539 4.34642 60.0409 4.4574C60.1102 4.54673 60.1146 4.66954 60.1146 4.75197C60.1146 4.8432 60.1077 4.94998 60.0409 5.02622C60.0123 5.05963 59.9582 5.09944 59.8801 5.10616V5.11307C59.952 5.11493 60.0123 5.14647 60.0501 5.1819C60.1344 5.26436 60.1369 5.38713 60.1369 5.47164C60.1369 5.60129 60.1257 5.71902 60.034 5.81088C59.9538 5.89082 59.8603 5.90205 59.7691 5.90205H58.9354V5.66811H59.5618C59.6597 5.66811 59.7219 5.66811 59.7647 5.64139C59.8292 5.59858 59.8336 5.523 59.8336 5.456C59.8336 5.38477 59.831 5.31575 59.7691 5.26871C59.7218 5.23329 59.6505 5.23092 59.6219 5.23092H58.975V4.99698H59.604C59.6505 4.99698 59.7244 4.99446 59.7691 4.94562C59.8068 4.90329 59.8112 4.83629 59.8112 4.78304C59.8112 4.72711 59.8068 4.66443 59.7691 4.62244C59.7288 4.58011 59.6728 4.57591 59.6283 4.57591H58.9621V4.34131L58.9621 4.34137Z"
              fill="#FEFEFE"
            />
            <path
              d="M62.6143 5.66829H63.3096C63.3545 5.66829 63.4282 5.66343 63.4685 5.60144C63.4901 5.56536 63.5014 5.48788 63.5014 5.42962C63.5014 5.38731 63.4971 5.31359 63.4685 5.27375C63.4524 5.25145 63.4146 5.21549 63.3321 5.21549H62.9176C62.8575 5.21549 62.757 5.21549 62.6944 5.17082C62.6074 5.10883 62.6057 4.99028 62.6057 4.93703V4.54256C62.6057 4.46679 62.6324 4.42231 62.6548 4.39748C62.7055 4.34404 62.7817 4.34155 62.8128 4.34155H63.7536V4.57616H62.9735C62.9512 4.57616 62.9245 4.58035 62.9134 4.59597C62.8998 4.61146 62.8998 4.63814 62.8998 4.65609V4.85025C62.8998 4.88117 62.8998 4.92599 62.9245 4.95255C62.9486 4.97741 62.9977 4.98158 63.0293 4.98158H63.4592C63.5528 4.98158 63.6465 5.00158 63.7248 5.09965C63.8027 5.19757 63.8045 5.31179 63.8045 5.41842C63.8045 5.55917 63.7958 5.71734 63.6979 5.81296C63.6062 5.90226 63.4839 5.90226 63.4014 5.90226H62.6143V5.66832V5.66829Z"
              fill="#FEFEFE"
            />
            <path
              d="M59.7597 14.6254C59.7597 14.6192 59.7553 14.6168 59.751 14.6168C59.7468 14.6168 59.7424 14.6192 59.7244 14.6391L59.1271 15.301C59.116 15.3121 59.1003 15.3283 59.1003 15.3388C59.1003 15.3481 59.1047 15.366 59.1401 15.366H59.7597V14.6254L59.7597 14.6254ZM59.7597 15.5999H59.0112C58.9707 15.5999 58.92 15.5999 58.8728 15.5527C58.8461 15.5242 58.8193 15.4727 58.8193 15.3834C58.8193 15.3233 58.8306 15.2631 58.9062 15.1806L59.6598 14.3736C59.7332 14.2935 59.8249 14.2849 59.8758 14.2849C59.9204 14.2849 59.9762 14.2911 60.0167 14.3339C60.0563 14.376 60.0544 14.4164 60.0544 14.4542V15.366H60.2529V15.5999H60.0544V15.8629H59.7597V15.5999V15.5999Z"
              fill="#FEFEFE"
            />
            <path
              d="M55.4149 15.8707C55.3859 15.8707 55.25 15.8664 55.25 15.6834C55.25 15.5606 55.2991 15.5098 55.3455 15.4787L56.0681 14.9837C56.1593 14.9211 56.1662 14.8473 56.1662 14.7586C56.1662 14.6804 56.1593 14.629 56.1344 14.5979C56.1079 14.5644 56.0519 14.5445 55.9762 14.5445H55.3548V14.3101H56.1127C56.1842 14.3101 56.2951 14.3194 56.3758 14.4019C56.4601 14.4863 56.4694 14.6091 56.4694 14.7251C56.4694 14.8094 56.4669 14.9348 56.3981 15.0346C56.3578 15.0929 56.3088 15.1264 56.2617 15.1599L55.6333 15.5965C55.6264 15.601 55.6084 15.6121 55.6084 15.6233C55.6084 15.6276 55.6109 15.6368 55.6264 15.6368H56.4024V15.8707H55.4149L55.4149 15.8707Z"
              fill="#FEFEFE"
            />
            <path
              d="M62.4875 14.5683C62.4875 14.5037 62.4893 14.4256 62.552 14.3655C62.6077 14.3121 62.6809 14.3027 62.7392 14.3027H63.4793C63.5883 14.3027 63.7224 14.3096 63.8136 14.4454C63.8873 14.5528 63.8918 14.6756 63.8918 14.7891C63.8918 14.9026 63.8873 15.0255 63.8246 15.1414C63.7845 15.2172 63.7088 15.3015 63.5301 15.3015H63.3297V15.3126L63.9228 15.8634H63.5524L63.04 15.3573C63.0091 15.3264 62.9663 15.2704 62.9663 15.2258V15.1905C62.9663 15.1277 63.0135 15.0831 63.0978 15.0831H63.3924C63.4433 15.0831 63.5128 15.0764 63.5524 15.0142C63.5842 14.9653 63.5884 14.8536 63.5884 14.8003C63.5884 14.6775 63.5637 14.6155 63.5326 14.5838C63.4861 14.5373 63.4147 14.5373 63.3677 14.5373H62.8907C62.8596 14.5373 62.8373 14.5391 62.8124 14.5615C62.7814 14.5882 62.7814 14.6173 62.7814 14.6484V15.8634H62.4874V14.5683L62.4875 14.5683Z"
              fill="#FD0000"
            />
          </svg>
        </div>
        <div class="header__navbar__links">
          <a
            v-for="(link, index) in navbar"
            :key="{ index }"
            :href="`#${link.id}`"
            class="header__navbar__links-item"
            >{{ link.name }}</a
          >
        </div>
        <button
          @click="toggleRequestForm($event)"
          class="header__button btn-primary"
        >
          {{ order_call }}
        </button>
      </div>
    </nav>
  </section>
</template>

<script>
import siteData from "@/config.json";

export default {
  name: "HeaderComponent",
  data() {
    return {
      navbar: siteData.navbar,
      contacts: siteData.contacts,
      order_call: siteData.order_call,
      isHidden: true,
      offset: false,
      offsetDesk: false,
    };
  },
  methods: {
    toggleNavlinks() {
      this.isHidden = !this.isHidden;
      this.emitter.emit("open-request-form", {
        open: true,
      });
    },
    toggleRequestForm() {
      this.emitter.emit("open-request-form", {
        open: false,
        formPosition: "Панель навигации",
      });
      if (this.isHidden === false) {
        this.isHidden = true;
      }
    },
  },
  mounted() {
    window.document.onscroll = () => {
      let navMob = document.getElementById("navMob");
      // let navDesk = document.getElementById("navDesk");
      if (window.scrollY > navMob.offsetTop) {
        this.offset = true;
      } else {
        this.offset = false;
      }
      // if (window.scrollY >= navDesk.offsetTop ) {
      //   this.offsetDesk = true;
      // } else {
      //   this.offsetDesk = false;
      // }
    };
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.slideMenu-enter-active {
  transition: all 0.3s ease-out;
}

.slideMenu-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slideMenu-enter-from,
.slideMenu-leave-to {
  transform: translateX(50px);
  opacity: 0;
}
</style>
