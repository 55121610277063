<template>
  <section id="contacts" class="contact-form">
    <form @submit.prevent class="form">
      <h2 v-if="!isSent" class="form__title">{{ title }}</h2>

      <h2 v-if="isSent" class="form__title">
        Заявка отправлена
        <span><img src="@/img/icons/success-g.svg" alt="" /></span>
      </h2>

      <label class="form__label" for="name">Ваше имя</label>
      <input placeholder="Имя" class="form__input" type="text" name="name" id="name" v-model="name"
        :class="{ error: !name && errorStyle }" />
      <label class="form__label" for="phone">Ваш телефон</label>
      <input v-maska="'+7 (###) ###-##-##'" placeholder="Телефон" class="form__input" type="text" name="phone" id="phone"
        v-model="phoneNumber" :class="{ error: !phoneNumber && errorStyle }" />
      <div class="form__footer">
        <p class="form__text">
          Нажимая «Оставить заявку», вы соглашаетесь с
          <button @click="togglePrivacy">политикой обработки</button>
          персональных данных
        </p>
        <button @click="requestCall" class="btn-primary form__btn">
          Оставить заявку
        </button>
      </div>
    </form>
    <div class="socials">
      <h2 class="socials__title">Мы в социальных сетях</h2>
      <div class="socials__icons">
        <a href="https://t.me/avtoban_ekb" target="_blank"><img src="../../img/icons/telegram-g.svg"
            alt="telegram icon" /></a>
        <a href="https://vk.com/avtobanekb" target="_blank"><img src="../../img/icons/vk-g.svg" alt="VK icon" /></a>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "ContactFormComponent",
  data() {
    return {
      URL: process.env.VUE_APP_API_URL,
      title: "Мы ответим на все ваши вопросы",
      name: "",
      phoneNumber: "",
      isSent: false,
      errorStyle: false,
      privacyIsHidden: true,
    };
  },
  methods: {
    validation() {
      return this.name && this.phoneNumber;
    },
    requestCall() {
      if (!this.validation()) {
        this.errorStyle = true;
      }
      if (this.validation()) {
        const formData = {
          name: this.name,
          phone: this.phoneNumber,
          form_position: "контактная форма",
        };
        axios
          .post(`${this.URL}form/callback`, formData)
          .then((response) => response)
          .catch((error) => console.log(error));

        // const params = {
        //   fio: formData.name,
        //   phoneNumber: formData.phone.replace(/\D/g, ""),
        //   subject: "Екатеринбург",
        //   sessionId: window.ct("calltracking_params", "xse1pw5k").sessionId,
        // };

        // const siteId = "54043";

        // axios
        //   .get(
        //     `https://api.calltouch.ru/calls-service/RestAPI/requests/${siteId}/register/`,
        //     { params }
        //   )
        //   .then((response) => {
        //     if (response.data.subject === "Екатеринбург") {
        //       window.ym(90439488, "reachGoal", "lead_sale");
        //     } else {
        //       window.ym(90439488, "reachGoal", "lead_service");
        //     }
        //     console.log("Отправка заявки в CallTouch удалась");
        //   })
        //   .catch((error) => {
        //     console.warn(
        //       `Отправка заявки в CallTouch не удалась. Причина: ${error}.`
        //     );
        //   });

        this.isSent = true;
        this.errorStyle = false;
        this.name = null;
        this.phoneNumber = null;
        setTimeout(() => (this.isSent = false), 3000);
      }
    },
    togglePrivacy() {
      this.emitter.emit("open-privacy", {
        open: false,
      });
    },
  },
  mounted() {
    this.emitter.on("open-privacy", (data) => {
      this.privacyIsHidden = data.open;
    });
  },
};
</script>

<style lang="scss"></style>
