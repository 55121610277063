<template>
  <div class="models__cars">
    <div v-for="model in models" :key="model.id" :id="model.id" class="models__car">
      <div class="models__car-header">
        <div class="models__car-name">
          <h3>{{ model.name }}</h3>
          <picture class="models__car-gift">
            <source :srcset="require(`../../img/pics/gift-mobile.svg`)" media="(max-width: 1041px)" />
            <img :src="require(`../../img/pics/gift.svg`)" alt="" />
          </picture>
          <a class="models__car-certificate" v-if="model.certificate" :href="model.certificate" target="_blank"><img
              src="../../img/pics/AG_1.png" alt="диплом автомобиля"></a>
        </div>
        <span v-if="model.id === 'tiggo8promax'" class="models__car-lead">Премиальный внедорожник</span>
      </div>
      <div class="models__car-grid">
        <img :src="require(`../../img/pics/cars/${model.id}/${model.id}.png`)" :alt="model.name"
          class="models__car-image" />
          <div class="models__car-desc">
          <h4 class="models__car__price">
            <template v-if="model.id === 'e5'">Выгода до <span>599 000</span> ₽</template>
          </h4>
          <div class="models__car-details">
            <p class="models__car__installments" v-if="model.id === 'e5'">Гарантия 3 года</p>
            
            <p class="models__car__installments">Авторассрочка <span>0%</span></p>
            <p v-if="model.gift" class="models__car__credit">
              Зимние шины, КАСКО, ТО-0 в подарок
            </p>
            <p class="models__car__instock">
              {{ model.in_stock }}
            </p>
          </div>
        </div>
        <div class="models__car-buttons">
          <button @click="toggleRequestForm($event, model.name)" class="models__car-btn models__car-btn-2 btn-primary">
            Рассчитать кредит
          </button>
          <button @click="toggleRequestForm($event, model.name)" class="models__car-btn models__car-btn-1 btn-secondary">
            Узнать цену
          </button>
        </div>

        <ModelSlider :model="model" />

      </div>
    </div>
  </div>
</template>

<script>
import siteData from "@/config.json";
import ModelSlider from "./ModelSlider.vue";
export default {
  name: "ModelComponent",
  data() {
    return {
      models: siteData.models_range.models,
    };
  },
  components: { ModelSlider },

  methods: {
    toggleRequestForm(event, model) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
        car: model,
        form_position: "форма модельного ряда",
      });
    },
  },
};
</script>
