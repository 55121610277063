<template>
  <Countdown
    mainColor="#ffffff"
    labelColor="#ffffff"
    secondFlipColor="#ffffff"
    mainFlipBackgroundColor="#992932"
    secondFlipBackgroundColor="#992932"
    :deadline="setNextDate"
    labelSize=".8rem"
    countdownSize="3.3rem"
    :labels="{
      days: 'дней',
      hours: 'часов',
      minutes: 'минут',
      seconds: 'секунд',
    }"
    class="newCounter"
  />
</template>

<script>
import { Countdown } from "vue3-flip-countdown";
export default {
  components: { Countdown },
  data() {
    return {};
  },
  methods: {
    formatDate(date) {
      const formattedDate =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      return formattedDate;
    },
    getNow() {
      //making new date each time when visiting the website
      const nextDate = new Date();
      nextDate.setDate(nextDate.getDate() + 1);
      let date = this.formatDate(nextDate);
      let dateTime = date;

      //checking for local storage and setting dateTime to it
      if (localStorage.getItem("timer") === null) {
        localStorage.setItem("timer", dateTime);
      }
      if (localStorage.getItem("timer")) {
        dateTime = localStorage.getItem("timer");
      }

      //here we check if the local storage time is smaller than
      // today so we can run the function again and set new time
      if (new Date(dateTime) - new Date(date) <= 0) {
        nextDate.setDate(nextDate.getDate() + 3);
        date = this.formatDate(nextDate);
        dateTime = date;
        localStorage.setItem("timer", dateTime);
        return dateTime;
      }
      return dateTime;
    },
  },
  computed: {
    setNextDate() {
      return this.getNow();
    },
  },
};
</script>

<style lang="scss">
// .flip-clock.newCounter {
//   margin: 0;
// }
// .flip-clock__piece.flip {
//   background-color: transparent;
// }

// .flip-clock__card .flip-card > br {
//   font-weight: 300;
// }
// .flip-card__top,
// .flip-card__back,
// .flip-card__bottom,
// .flip-card__back-bottom {
//   font-weight: 300;
// }
</style>
