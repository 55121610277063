<template>
  <section id="map" class="map">
    <div style="height: 95vh">
      <yandex-map :controls="[]" ref="map" style="width: 100%; height: 100%" :zoom="10" :coords="[56.668463, 60.671639]"
        :options="{
          suppressMapOpenBlock: true,
        }">
        <ymap-marker :icon="markerIcon" :markerId="123" :coords="address1" />
      </yandex-map>
      <div class="contacts__bg"></div>
      <div class="contacts">
        <div v-for="contact in contacts" :key="contact.phone" class="contacts__contact">
          <p class="contacts__address">
            {{ contact.city }} <br />{{ contact.street }}
          </p>
          <a class="contacts__phone" :href="`tel:${contact.phone_number}`"><span><img src="@/img/icons/call-answer-g.svg"
                alt="" /></span>
            {{ contact.phone_text }}</a>
          <p class="contacts__time">
            <span><img src="@/img/icons/clock-g.svg" alt="" /></span>
            {{ contact.time }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import siteData from "@/config.json";
export default {
  name: "YandexMapComponent",
  data() {
    return {
      contacts: siteData.contacts,
      detailedControls: { zoomControl: { position: { right: 10, top: 50 } } },
      address1: [
        siteData.contacts.address_1.map.lat,
        siteData.contacts.address_1.map.long,
      ],
      markerIcon: {
        layout: "default#imageWithContent",
        imageHref: require("../../img/icons/map_bullit-g.svg"),
        imageSize: [40, 50],
        imageOffset: [0, 0],
      },
    };
  },
};
</script>

<style lang="scss">
.yandex-container {
  height: 100%;
}
</style>
