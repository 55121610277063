<template>
  <section id="request-form">
    <Transition name="fade">
      <div @click="closeModal" v-show="!isHidden" class="modal__bg"></div>
    </Transition>
    <Transition name="slide-fade">
      <form @submit.prevent="submitForm" v-show="!isHidden && !isSent" class="modal">
        <div @click="closeModal" class="modal__close">
          <svg width="23" height="22" viewBox="0 0 23 22" fill="" xmlns="http://www.w3.org/2000/svg">
            <path fill="#000" d="M1.954.04L22.46 20.546l-1.414 1.414L.54 1.454z"></path>
            <path fill="#000" d="M.54 20.546L21.046.04l1.414 1.414L1.954 21.96z"></path>
          </svg>
        </div>
        <h2 class="modal__title">{{ title || "Оставить заявку на звонок" }}</h2>
        <div class="modal__inputs">
          <label class="modal__label" for="modalName">Ваше имя</label>
          <input placeholder="Имя" class="modal__input" type="text" name="modalName" id="modalName" v-model="name"
            :class="{ error: !name && errorStyle }" />
          <label class="modal__label" for="modalPhone">Ваш телефон</label>
          <input v-maska="'+7 (###) ###-##-##'" placeholder="Телефон" class="modal__input" type="text" name="modalPhone"
            id="modalPhone" v-model="phoneNumber" :class="{ error: !phoneNumber && errorStyle }" />
        </div>
        <div class="modal__footer">
          <p class="modal__text">
            Нажимая «Оставить заявку», вы соглашаетесь с
            <button @click="togglePrivacy">политикой обработки</button>
            персональных данных
          </p>
          <button @click="submitForm" class="btn-primary modal__btn">
            Заказать звонок
          </button>
        </div>
      </form>
    </Transition>
    <Transition name="fade">
      <div class="success modal" v-show="!isHidden && isSent">
        <img class="success__img" src="@/img/icons/success-g.svg" alt="" />
        <h2 class="modal__title success__title">Заявка отправлена</h2>
        <p class="modal__text success__text">
          Спасибо за обращение! В ближайшее время наши менеджеры свяжутся с вами
          и ответят на все интересующе вопросы
        </p>
      </div>
    </Transition>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "RequestFormComponent",
  data() {
    return {
      URL: process.env.VUE_APP_API_URL,
      isHidden: true,
      privacyIsHidden: true,
      title: "",
      name: "",
      phoneNumber: "",
      car: "",
      form_position: "",
      isSent: false,
      errorStyle: false,
    };
  },
  methods: {
    validation() {
      return this.name && this.phoneNumber ? true : false;
    },
    submitForm() {
      if (!this.validation()) {
        this.errorStyle = true;
      }
      if (this.validation()) {
        const formData = {
          name: this.name,
          phone: this.phoneNumber,
          car: this.car,
          form_position: this.form_position,
        };
        axios
          .post(`${this.URL}form/callback`, formData)
          .then((response) => response)
          .catch((error) => console.log(error));

        // const params = {
        //   fio: formData.name,
        //   phoneNumber: formData.phone.replace(/\D/g, ""),
        //   subject: this.getCenterName(this.location),
        //   sessionId: window.ct("calltracking_params", "xse1pw5k").sessionId,
        // };

        // const siteId = "54043";

        // axios
        //   .get(
        //     `https://api.calltouch.ru/calls-service/RestAPI/requests/${siteId}/register/`,
        //     { params }
        //   )
        //   .then((response) => {
        //     if (response.data.subject === "Екатеринбург") {
        //       window.ym(90439488, "reachGoal", "lead_sale");
        //     } else {
        //       window.ym(90439488, "reachGoal", "lead_service");
        //     }
        //     console.log("Отправка заявки в CallTouch удалась");
        //   })
        //   .catch((error) => {
        //     console.warn(
        //       `Отправка заявки в CallTouch не удалась. Причина: ${error}.`
        //     );
        //   });

        this.isSent = true;
        this.errorStyle = false;
        this.name = null;
        this.phoneNumber = null;
        setTimeout(() => (this.isHidden = true), 2000);
        setTimeout(() => (this.isSent = false), 2000);
      }
    },
    getCenterName(value) {
      return value === "dc_1" ? "Екатеринбург" : "Березовский";
    },
    closeModal() {
      this.isHidden = true;
    },
    togglePrivacy() {
      this.emitter.emit("open-privacy", {
        open: false,
      });
    },
  },
  mounted() {
    this.emitter.on("open-request-form", (data) => {
      this.isHidden = data.open;
      this.title = data.title;
      this.car = data.car;
      this.form_position = data.form_position;
    });
    this.emitter.on("open-privacy", (data) => {
      this.privacyIsHidden = data.open;
    });
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
